import AxiosInstance from 'axios';
import { create } from 'zustand';

const axios = AxiosInstance.create({
  baseURL: process.env.API_ENDPOINT,
});

const endpoint = '/blogs?populate=*';

interface Pagination {
  page: number;
  pageSize: number;
  pageCount: number;
  total: number;
}

interface Meta {
  pagination: Pagination;
}

interface ApiResponse {
  data: Blog[];
  meta: Meta;
}

interface BlogAttributes {
  title: string;
  date: string;
  content: Content[];
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  description: string;
  slug: string;
  thumbnail: Thumbnail;
  blog_categories: BlogCategories;
}

interface Content {
  type: string;
  children: Child[];
}

interface Child {
  text?: string;
  type?: string;
  bold?: boolean;
}

interface Thumbnail {
  data: {
    id: number;
    attributes: any;
  };
}

interface BlogCategories {
  data: BlogCategory[];
}

interface BlogCategory {
  id: number;
  attributes: {
    tags: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
  };
}

interface Blog {
  id: number;
  attributes: BlogAttributes;
}

interface BlogState {
  blogs: Blog[];
  isLoading: boolean;
  pagination: Pagination;
  getBlogs: (
    tag: string | null,
    page?: number,
    pageSize?: number,
    searchTitle?: string
  ) => Promise<void>;
}

export const useAllBlogs = create<BlogState>((set, get) => ({
  blogs: [],
  isLoading: false,
  pagination: {
    page: 1,
    pageSize: 25,
    pageCount: 0,
    total: 0,
  },
  getBlogs: async (
    tag,
    page = get().pagination.page,
    pageSize = get().pagination.pageSize,
    searchTitle = ''
  ) => {
    set({ isLoading: true });
    try {
      const response = await axios.get<ApiResponse>(`${endpoint}`, {
        params: {
          tag: tag,
          'pagination[page]': page,
          'pagination[pageSize]': pageSize,
          'filters[title][$containsi]': searchTitle,
        },
      });
      set({
        blogs: response.data.data,
        pagination: response.data.meta.pagination,
        isLoading: false,
      });
    } catch (error) {
      console.error('Error fetching all blogs', error);
      set({ isLoading: false });
    }
  },
}));
